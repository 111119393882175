import http from "@/utils/request";

//查询优惠券列表
export function getCouponList(data) {
  return http({
    method: "post",
    data: data,
    url: "/aijiaCoupon/myCouponGroupByShopId.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy'
  });
}

// 查询优惠券下产品列表
export function getPorductListByCoupon(data) {
  return http({
    method: "post",
    url: "/aijiaCoupon/myCouponGroupByShopIdUnfold.nd",
    data: data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy'
  });
}

// 获取微信链接
export function getMpSignature(data) {
  return http({
    method: "post",
    url: "/comm/wx/generateScheme.api",
    data: data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy'
  });
}

// 获取是否专卖店无促门店
export function storeHavePromoteSeller(data) {
  return http({
    method: "post",
    url: "/aijiaCoupon/storeHavePromoteSeller.nd",
    data: data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy'
  });
}

//获取产品价格接口
export function getPrices(data) {
  return http({
    method: "post",
    url: "/product/getPrices.nd",
    data
  });
}
//渠道采购价格接口
export function getChannelPrice(data) {
  return http({
    method: "post",
    url: "/product/fxPrice.nd",
    data
  });
}