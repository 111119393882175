<template>
  <div class="coupon-list-page">
    <div class="coupon-list" v-if="couponList.length > 0">
      <template v-for="item in couponList">
        <div class="coupon-item" :class="item.isExpired == 1 ? 'normal' : ''">
          <div class="coupon-category">{{ item.spartName }}</div>
          <div class="coupon-item-left">
            <div :class="item.isExpired == 1 ? 'coupon-item-left-title coupon-item-left-title-normal' : 'coupon-item-left-title'">{{ item.name }}</div>
            <div class="coupon-item-left-time">有效期:{{ item.startTime }} ~ {{ item.endTime }}</div>
            <div class="item-left-desc" @click="handleShowPopup(item)">
              <p :class="item.isExpired == 1 ? 'normal' : ''">部分型号可用</p>
              <img class="arrow" v-if="item.isExpired == 1" src="../../assets/images/coupon-arrow-nomal.png" alt="" srcset="">
              <img class="arrow" v-else src="../../assets/images/coupon-arrow.png" alt="" srcset="">
            </div>
            <!-- <div class="item-left-price">
              <p class="price-symbol">￥</p>
              <p class="price-value">{{ item.couponPreferentialAmount }}</p>
            </div>
            <div class="item-left-desc" @click="handleShowPopup(item)">
              <p>部分型号可用</p>
              <img class="arrow" v-if="item.isExpired == 1" src="../../assets/images/coupon-arrow-nomal.png" alt="" srcset="">
              <img class="arrow" v-else src="../../assets/images/coupon-arrow.png" alt="" srcset="">
            </div> -->
          </div>
          <div class="coupon-item-right">
            <div class="coupon-item-btn" v-if="item.isExpired == 1">已失效</div>
            <div class="coupon-item-btn" v-if="isSpecialShow == 1 && item.isExpired == 0" @click="handleToUse(item)">立即使用</div>
          </div>
          <!-- <div class="coupon-item-right">
            <div class="coupon-item-info">
              <p class="title">{{ item.name }}</p>
              <p>{{ item.activityName || '' }}</p>
              <p>有效期:{{ item.startTime }} ~ {{ item.endTime }}</p>
            </div>
            <div class="coupon-item-btn" v-if="item.isExpired == 1">已失效</div>
            <div class="coupon-item-btn" v-if="isSpecialShow == 1 && item.isExpired == 0" @click="handleToUse(item)">去使用</div>
          </div> -->
        </div>
      </template>
      <p class="empty-height"></p>
      <van-popup
        v-model="show"
        position="bottom"
        :style="{ height: '80%', background: 'linear-gradient( 180deg, #FFFFFF 0%, #F5F5F5 100%)', 'border-radius': '12px 12px 0px 0px' }">
        <div class="coupon-popup">
          <div class="popup-title">
            该优惠券可用的产品型号
            <span class="iconfont icon-danchuangguanbi-anniu popup-close" @click="handleClosePopup"></span>
          </div>
          <div class="popup-list" v-if="popupList.length > 0">
            <template v-for="item in popupList">
              <div class="popup-item">
                <h-img2
                  class="popup-item-img"
                  :imgUrl="'/product/pic.nd?productId=' + item.productId"
                  :errorImg="'/assets/new/images/product/' + item.materialGroupCode + '.jpg'"
                  :isUseBasePath="true"
                  alt=""
                />
                <div class="popup-item-info">
                  <p class="model">{{ item.zzprdmodel || '' }}</p>
                  <p class="label"><span>{{ item.brand }}</span><span>{{ item.color || '' }}</span></p>
                  <p class="desc" v-if="item.price && item.price != 0">新机到手价 = ¥{{ item.price || 0 }} - 优惠金额</p>
                </div>
                <div class="popup-item-btn" v-if="item.isShowUseBtn && item.isShowUseBtn == 1" @click="handleToOrder(item)">去下单</div>
              </div>
            </template>
            <p class="empty-height"></p>
          </div>
          <div class="no-data" v-else>
            <img src="https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/xcx/img/no-data.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=3724445736&Signature=iaaoMXnmQBQsO0XnT27bMhMBOCI%3D" alt="">
            <div class="tip">暂无数据</div>
          </div>
        </div>
      </van-popup>
      <van-toast id="van-toast"></van-toast>
    </div>
    <div class="no-data" v-else>
      <img src="https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/xcx/img/no-data.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=3724445736&Signature=iaaoMXnmQBQsO0XnT27bMhMBOCI%3D" alt="">
      <div class="tip">暂无数据</div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import "@/common/wx.js";
  import { Popup, Toast } from 'vant';
  import 'vant/lib/index.css';
  import { env } from "@/common/constant";
import { checkRetailL } from "@/views/channelInput/saleNew/api";
  import { 
    getCouponList, 
    getPorductListByCoupon, 
    getMpSignature, 
    storeHavePromoteSeller,
  } from './api';
  Vue.use(Popup).use(Toast);
  export default {
    data () {
      return {
        openId: '',
        account: '', // 账号
        shopCisCode: '', // 门店cisCode
        show: false, // 弹窗标识
        couponList: [], // 优惠券列表
        popupList: [], // 优惠券下产品列表
        isSpecialShow: 0, // 是否是专卖店无促销员门店
        hxOrderNo: '', // 回收单号
        couponItem: null, // 单条优惠券信息
      }
    },
    methods: {
      // 显示弹窗
      handleShowPopup(item) {
        if(item.isExpired == 1) {
          return false
        }
        this.couponItem = item
        this.getPorductListByCoupon(item)
        this.show = true
      },
      // 关闭弹窗
      handleClosePopup() {
        this.show = false
      },
      // 查询优惠券列表
      getCouponList() {
        Toast.loading({
          message: '加载中...',
          duration: 0,
          forbidClick: true,
        });
        getCouponList({
          account: this.account,
          shopCisCode: this.shopCisCode,
        }).then(res => {
          Toast.clear()
          const data = res.data
          if(data && Array.isArray(data)) {
            this.couponList = data.map(el => {
              el.startTime = el.endDate && el.startDate.slice(0, 10)
              el.endTime = el.endDate && el.endDate.slice(0, 10)
              return {
                ...el
              }
            })
          }
        }).catch(() => {
          Toast.clear()
        })
      },
      // 查询优惠券下产品列表
      getPorductListByCoupon(item) {
        Toast.loading({
          message: '加载中...',
          duration: 0,
          forbidClick: true,
        });
        getPorductListByCoupon({
          account: this.account,
          shopCisCode: this.shopCisCode,
          recycleOrder: item.recycleOrder,
          name: item.name,
          activityName: item.activityName,
          // couponPreferentialAmount: item.couponPreferentialAmount,
          spartId: item.spartId,
          startDate: item.startDate,
          endDate: item.endDate
        }).then(res => {
          Toast.clear()
          const data = res.data
          if(data && Array.isArray(data)) {
            this.popupList = data
            this.popupList.forEach((el, index) => {
              this.getPriceValue(el, index)
            })
          }
        }).catch(() => {
          Toast.clear()
        })
      },
      getPriceValue(el, index) {
        checkRetailL({
          id: el.productId
        }).then(res => {
          if(res.data && res.data.code == 0){
            const { money } = res.data
            this.popupList[index].price = money || 0
          }
        })
      },
      handleToUse(item) {
        const query = `openid=${this.openId}&ciscode=${this.shopCisCode}&hxOrderNo=${item.recycleOrder || ''}`
        this.getMpSignature(query) // 获取微信配置
      },
      handleToOrder(item) {
        let productInfo = {
          matklId: item.materialGroupCode || '',
          matklName: item.materialGroupName || '',
          productCode: item.productId || '',
          model: item.zzprdmodel || '',
          colour: item.color || '',
          price: item.price || '',
          borderedQty: 1,
          couponList: [{
            recycleOrder: item.recycleOrder || '',
            recycleType: item.recycleType || '',
            id: item.id || '',
            name: this.couponItem.name || '',
            couponPreferentialAmount: this.couponItem.couponPreferentialAmount || '',
            endDate: this.couponItem.endDate || '',
            checked: true
          }]
        }
        productInfo = encodeURIComponent(JSON.stringify(productInfo))
        let query = `openid=${this.openId}&ciscode=${this.shopCisCode}&hxOrderNo=${item.recycleOrder || ''}&productInfo=${productInfo}`
        this.getMpSignature(query) // 获取微信配置
      },
      getQueryString(){
        let url =  window.location.href;
        let str = url.split("?")[1];
        let item = str.split("&");
        let obj = {};
        for(let i=0;i<item.length;i++){
          let arr = item[i].split("=");
          obj[arr[0]] = arr[1];
        }
        return obj
      },
      // 获取微信配置
      getMpSignature(query) {
        let env_version = 'release'
        if(env == "dev" || env == "test" || env == "uat") {
          env_version = 'develop'
        }
        getMpSignature({
          path: '/pages/orders/order-info-submit/index',
          query: query,
          env_version: env_version
        }).then(res => {
          const { code, data, msg } = res.data
          if(code == 0 && data && data.errcode == 0) {
            window.location.href = data.openlink
          }else {
            Toast.fail(data.errmsg || msg || '系统异常，请稍后重试')
          }
        })
      },
      // 查询是否专卖店无促门店
      storeHavePromoteSeller() {
        storeHavePromoteSeller({
          shopCisCode: this.shopCisCode
        }).then(res => {
          const { code, data } = res.data
          if(code == 0) {
            this.isSpecialShow = data
          }
        })
      }
    },
    mounted() {
      document.title = '我的卡券'
      const { openid, ciscode, account, hxOrderNo }  = this.getQueryString()
      this.openId = openid || ''
      this.account = account || ''
      this.shopCisCode = ciscode || ''
      this.hxOrderNo = hxOrderNo || ''
      this.getCouponList() // 查询优惠券列表
      this.storeHavePromoteSeller() // 查询是否专卖店无促门店
    }
  }
</script>
<style lang="less" scoped>
.coupon-list-page{
  height: 100vh;
  .no-data{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      width: 144px;
      height: 118px;
    }
    .tip{
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: #777777;
      margin-top: 9px;
    }
  }
}
.coupon-list{
  height: 100vh;
  margin: 0 15px;
  padding-top: 15px;
  .empty-height{
    height: 9px;
    height: calc(9px + constant(safe-area-inset-bottom));
    height: calc(9px + env(safe-area-inset-bottom));
  }
  .coupon-item{
    height: 96px;
    background: url('../../assets/images/coupon-active.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    color: #FE2335;
    margin-bottom: 9px;
    position: relative;
    .coupon-category{
      position: absolute;
      top: 0;
      left: 0;
      padding: 2px 5px;
      min-width: 40px;
      text-align: center;
      background: #FD6A6E;
      border-radius: 10px 0 6px 0;
      color: #FFFFFF;
      font-size: 10px;
    }
    .coupon-item-left{
      width: 69%;
      height: 96px;
      padding: 20px 12px 16px;
      &-title{
        height: 20px;
        text-align: left;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
        font-size: 14px;
        color: #FE2335;
        line-height: 20px;
      }
      &-title-normal{
        color: #AAAAAA;
      }
      &-time{
        height: 16px;
        margin-top: 4px;
        width: 215px;
        font-size: 11px;
        color: #777777;
        line-height: 16px;
        text-align: left;
      }
      .item-left-desc{
        display: flex;
        align-items: center;
        margin-top: 4px;
        color: #FE2335;
        p{
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
        }
        .arrow{
          width: 8px;
          height: 8px;
          margin-left: 2px;
        }
        .normal{
          color: #AAAAAA
        }
      }
    }
    .coupon-item-right{
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
      .coupon-item-btn{
        width: 80px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 16px;
        border: .5px solid #FE2335;
        color: #FE2335;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &.normal{
      background: url('../../assets/images/coupon-normal.png') no-repeat;
      background-size: 100% 100%;
      color: #AAAAAA;
      .coupon-category{
        background: #CCCCCC;
      }
      .coupon-item-right{
        .coupon-item-btn{
          border: .5px solid #CCCCCC;
          color: #AAAAAA;
        }
      }
    }
  }
  .coupon-popup{
    display: flex;
    flex-direction: column;
    height: 100%;
    .popup-title{
      flex-shrink: 0;
      position: relative;
      padding-top: 20px;
      padding-bottom: 18px;
      font-weight: 500;
      font-size: 18px;
      color: #262626;
      line-height: 25px;
      .popup-close{
        position: absolute;
        top: 22px;
        right: 16px;
        font-size: 16px;

      }
    }
    .popup-list{
      margin: 0 8px;
      .popup-item{
        background: #FFFFFF;
        border-radius: 8px;
        padding: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .popup-item-img{
          flex-shrink: 0;
          width: 60px;
          height: 60px;
          background: #F7F7F7;
          border-radius: 4px;
        }
        .popup-item-info{
          margin-left: 8px;
          flex: 1;
          overflow: hidden;
          text-align: left;
          .model{
            margin-bottom: 4px;
            font-weight: 500;
            font-size: 14px;
            color: #262626;
            line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .label{
            font-weight: 400;
            font-size: 12px;
            color: #777777;
            line-height: 16px;
            span{
              margin-right: 8px;
            }
          }
          .desc{
            margin-top: 10px;
            font-weight: 500;
            font-size: 12px;
            color: #FE2335;
            line-height: 16px;
          }
        }
        .popup-item-btn{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 14px;
          border: 1px solid #FE2335;
          width: 64px;
          height: 28px;
          font-weight: 400;
          font-size: 14px;
          color: #FE2335;
          line-height: 20px;
        }
      }
    }
    .no-data{
      flex: 1;
      height: auto;
    }
  }
}
</style>